import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import { PrismicHomePage } from '@/domain/PrismicHomePage';
import { PrismicCataloguePage } from '@/domain/PrismicCataloguePage';
import { PrismicCategory } from '@/domain/PrismicCategory';

import HomeLinks from '@/containers/Home/HomeLinks';
import HomeReviews from '@/containers/Home/HomeReviews';
import HomeProducts from '@/containers/Home/HomeProducts';
import ImageDataType from '@/types/data/image';

interface HomeContainerProps {
    data: {
        prismicHomePage: PrismicHomePage;
        allPrismicCataloguePage: {
            edges: {
                node: PrismicCataloguePage;
            }[];
        }
        allPrismicCa: {
            edges: {
                node: PrismicCategory;
            }[];
        }
        
    };
    lang: string;
}

interface ProductLinksType {
    uid: string;
    title: string;
    image: ImageDataType;
}

const HomeContainerRoot = styled.section``;

const HomeContainer = ({ data, lang }: HomeContainerProps): JSX.Element => {
    const { home_content, home_links, catalogue_title, catalogue_items } = data?.prismicHomePage?.data;
    const { allPrismicCataloguePage, allPrismicCa } = data;

    const ContentData = {
        content: home_content?.raw
    }

    const LinksHome = home_links?.map(link => ({
        title: link?.home_link_title?.text,
        image: link?.home_link_image,
        link: `${lang}/${link?.home_link?.uid}`,
    }))
    
    const [filteredCatalogueLink] = allPrismicCataloguePage?.edges?.filter(catalogue => catalogue?.node?.lang === lang)
    const productLinks: ProductLinksType[] = [];
    catalogue_items?.map(catalogue => {
        return allPrismicCa?.edges?.map(category => {
            return category?.node?.data?.products?.map(product => {
                if (product?.product?.document?.uid === catalogue?.catalogue_item?.document?.uid) {
                    console.log(`/${filteredCatalogueLink?.node?.lang}/${filteredCatalogueLink?.node?.uid}/${category?.node?.uid}/${catalogue?.catalogue_item?.document?.uid}`,'testyyyyyy')
                    productLinks?.push({
                        uid: `/${filteredCatalogueLink?.node?.lang}/${filteredCatalogueLink?.node?.uid}/${category?.node?.uid}/${catalogue?.catalogue_item?.document?.uid}`,
                        title: catalogue?.catalogue_item?.document?.data?.product_title?.text,
                        image: catalogue?.catalogue_item?.document?.data?.product_gallery[0]?.product_image,
                    })
                }
                return;
            })
        })
    })
    const HomeProductsData = {
        title: catalogue_title?.text,
        products: productLinks
    }

    return (
        <HomeContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <HomeLinks data={LinksHome} />
            {/* <HomeReviews /> */}
            <HomeProducts {...HomeProductsData} lang={lang} />
            <ContactBox lang={lang} />
        </HomeContainerRoot>
    );
};

export default HomeContainer;

