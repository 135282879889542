import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';
import HomeContainer from '@/containers/Home/HomeContainer';
import { PrismicHomePage } from '@/domain/PrismicHomePage';
import { PrismicCataloguePage } from '@/domain/PrismicCataloguePage';
import { PrismicCategory } from '@/domain/PrismicCategory';
import { PageContext } from '@/domain/PageContext';

interface HomeTemplateProps {
    data: {
        prismicHomePage: PrismicHomePage;
        allPrismicCataloguePage: {
          edges: {
            node: PrismicCataloguePage;
          }[];
        }
        allPrismicCa: {
          edges: {
            node: PrismicCategory;
          }[];
        }
    }
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicHomePage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        home_content {
          raw
        }
        home_links {
          home_link_image {
            gatsbyImageData
            alt
          }
          home_link_title {
            text
          }
          home_link {
            uid
          }
        }
        catalogue_title {
          text
        }
        catalogue_items {
          catalogue_item {
            document {
              ... on PrismicProduct {
                id
                uid
                data {
                  product_title {
                    text
                  }
                  product_gallery {
                    product_image {
                      alt
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCataloguePage {
      edges {
        node {
          lang
          uid
        }
      }
    }
    allPrismicCa {
      edges {
        node {
          lang
          uid
          data {
            products {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    lang
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const HomeTemplate = ({ pageContext, data }: HomeTemplateProps): JSX.Element => {
  const { alternate } = pageContext;  
  const { lang, alternate_languages } = data?.prismicHomePage;  
  const { meta_title, meta_description } = data?.prismicHomePage?.data;

  const selectlang = {
    lang,
    uid: '',
    alternate_languages,
  };
  const seo = {
      title: meta_title,
      description: meta_description,
      lang: lang,
      url: `${lang}`
  }
  const linkAlternate = alternate?.map(data => ({
      lang: data?.lang,
      url: `${data?.lang}`,
  }))

  return (
    <Layout selectLang={selectlang} isHome={true}>
      <SEO lang={seo.lang} title={`${seo.title}`} url={seo.url} description={seo.description} alternate={linkAlternate} />
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <HomeContainer data={data} lang={lang} />
    </Layout>
  );
};

export default HomeTemplate;
