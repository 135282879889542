import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography, Container } from '@/components/atoms';
import { ProductThumbnail } from '@/components/molecules';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';


interface HomeProductsProps {
    title: string;
    products: {
        uid: string;
        title: string;
        image: ImageDataType;
    }[];
    lang: string;
}

const HomeProductsRoot = styled.section`
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(8)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;

const Title = styled(Typography)`
    color: ${({ theme }) => theme.color.black.main};
    width: 100%;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

const HomeProductsContainer = styled(Container)`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    ${mq('md')} {
        justify-content: flex-start;
    }
`;

const HomeProductThumbnail = styled(ProductThumbnail)`
    display: flex;
    width: 100%;
    ${mq('xxs')} {
        width: 100%;
    }
    ${mq('xs')} {
        width: 80%;
    }
    ${mq('sm')} {
        width: 60%;
    }
    ${mq('md')} {
        width: 45%;
    }
    ${mq('lg')} {
        width: 30%;
    }
    ${mq('xl')} {
        width: 30%;
    }
`;


const HomeProducts = ({ title, products }: HomeProductsProps): JSX.Element => {

    return (
        <HomeProductsRoot>
            <Title variant="h1">
                {title}
            </Title>
            <HomeProductsContainer maxWidth="lg">
                {products?.map((product, i) => (
                    <HomeProductThumbnail key={i} {...product} />
                ))}
            </HomeProductsContainer>
        </HomeProductsRoot>
    );
};

export default HomeProducts;

