import React from 'react';
import styled from '@emotion/styled';

import { Img, Link, Typography } from '@/components/atoms';
import mq from '@/styles/mq';
import ImageDataType from '@/types/data/image';


interface HomeLinksProps {
    data: {
        title: string;
        link: string;
        image: ImageDataType;
    }[];
}

interface ItemProps {
    title: string;
    link: string;
    image: ImageDataType;
}

const HomeLinksRoot = styled.section`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        flex-direction: row;
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;

const ItemImage = styled(Img)`
    width: 100%;
    height: 200px;
    transition: .5s;
    ${mq('xxs')} {
        width: 300px;
        height: 200px;
    }
    ${mq('xs')} {
        width: 460px;
        height: 230px;
    }
    ${mq('md')} {
        width: 350px;
        height: 200px;
    }
    ${mq('lg')} {
        width: 450px;
        height: 250px;
    }
    ${mq('xl')} {
        width: 600px;
        height: 300px;
    }
`;
const ItemRoot = styled(Link)`
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    &:hover ${ItemImage} {
        transform: scale(1.05);
    }
    display: block;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${mq('xxs')} {
        width: auto;
    }
    ${mq('md')} {
        margin-right: ${({ theme }) => theme.spacing(2)};
        margin-bottom: ${({ theme }) => theme.spacing(0)};
    }
`;


const ItemTitleContainer = styled.div`
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 45%;
    background-color: rgba(250, 250, 250, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    ${mq('xxs')} {
        top: 40%;
        height: 30%;
    }
`;

const ItemTitle = styled(Typography)`
    text-align: center;
    width: 100%;
    color: ${({ theme }) => theme.color.primary.main};
    /* padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)}; */
`;

const Item = ({ title, image, link }: ItemProps):JSX.Element => {
    return (
        <ItemRoot to={`/${link}`}>
            <ItemImage image={image} />
            <ItemTitleContainer>
                <ItemTitle variant="textMd" as="p">
                    {title}
                </ItemTitle>
            </ItemTitleContainer>
        </ItemRoot>
    )
}

const HomeLinks = ({ data }: HomeLinksProps): JSX.Element => {

    return (
        <HomeLinksRoot>
            {data?.map(item => (
                <Item {...item} />
            ))}
        </HomeLinksRoot>
    );
};

export default HomeLinks;
